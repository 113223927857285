<template>
  <div class="hd-record dosing-in-hd">
    <VContainer fluid>
      <VForm>
        <VContainer>
          <VRow>
            <VCol>
              <VCard>
                <div class="subtitle-2 text-center">每日藥物治療紀錄</div>
                <VDivider />
                <VRow>
                  <VCol cols="auto">
                    <VSimpleCheckbox v-model="enable" />
                  </VCol>
                  <VCol cols="2">
                    <VTextField hide-details label="藥品" value="鐵劑" readonly />
                  </VCol>
                  <VCol cols="2">
                    <VTextField
                      v-model="volumeString"
                      hide-details
                      label="劑量"
                      value="100"
                      suffix="mg+N/S100mL"
                    />
                  </VCol>
                  <VCol cols="1">
                    <VTextField v-model="timesString" hide-details label="IVD次數" suffix="次" />
                  </VCol>
                  <VCol cols="auto">
                    <VBtn color="secondary" :disabled="!enable" outlined @click="add()">增加</VBtn>
                  </VCol>
                </VRow>
              </VCard>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VCard>
                <div class="subtitle-2 text-center">給藥確認紀錄</div>
                <VDivider />
              </VCard>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HDRecordDosingInHD',
  data: () => ({
    enable: false,
    volumeString: '100',
    timesString: '5',
  }),
  methods: {
    add() {
      const volume = parseInt(this.volumeString, 10);
      const times = parseInt(this.timesString, 10);
      if (Number.isNaN(volume) || Number.isNaN(times)) {
        // eslint-disable-next-line no-alert
        window.alert('數字不合法');
      } else {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const dates = _.times(times, (i) => new Date(year, month, day + 7 * i));
        this.$store.commit('fe', { volume, times, dates });
      }
    },
  },
});
</script>

<style lang="scss">
.hd-record.dosing-in-hd {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 1200px;
        width: 1200px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
            .v-input,
            .v-btn {
              padding-top: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
